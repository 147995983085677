.mt-31 {
  margin-top: 1.94rem;
}
.ml-142 {
  margin-left: 8.88rem;
}
.mt-29 {
  margin-top: 1.81rem;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.header {
  padding: 0 1.69rem;
  height: 7.5rem;
}
.image {
  width: 5.06rem;
  height: 5.06rem;
}
.image_2 {
  width: 15.75rem;
  height: 3.5rem;
}
.group {
  margin-right: 0.63rem;
}
.section {
  padding: 3.25rem 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 8.81rem;
  height: 7.5rem;
}
.group_2 {
  line-height: 0.95rem;
}
.font {
  font-size: 1.06rem;
  font-family: SimSun;
  line-height: 0.97rem;
  color: #000000;
}
.text {
  line-height: 0.95rem;
}
.font_2 {
  font-size: 1.06rem;
  font-family: Times New Roman;
  line-height: 0.74rem;
  color: #000000;
}
.text_5 {
  margin-left: 0.38rem;
}
.group_3 {
  line-height: 0.98rem;
}
.text_2 {
  line-height: 0.98rem;
}
.text_6 {
  margin-left: 0.38rem;
}
.group_4 {
  line-height: 0.98rem;
}
.text_3 {
  line-height: 0.98rem;
}
.text_7 {
  line-height: 0.74rem;
}
.group_5 {
  line-height: 0.97rem;
}
.text_4 {
  line-height: 0.97rem;
}
.text_8 {
  margin-left: 0.5rem;
}
.group_6 {
  line-height: 0.97rem;
}
.text_9 {
  margin-left: 0.38rem;
  line-height: 0.74rem;
}
.image_3 {
  margin-left: 1.44rem;
  width: 2.13rem;
  height: 2.13rem;
}
.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  padding: 0 4.25rem 1.06rem 4.69rem;
}
.text-wrapper {
  padding-bottom: 0.25rem;
  border-bottom: solid 0.13rem #fe0000;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.84rem;
}
.section_2 {
  margin-right: 0.5rem;
  padding: 0.25rem 0.63rem;

  overflow: hidden;
  width: 15.06rem;
  height: 2.31rem;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.38rem;
  color: #cc3333;
}
.text_11 {
  color: #464646;
  line-height: 1.38rem;
}

.button_search {
  margin-right: 0%;
  margin-left: 1.0rem;
  line-height: 1.38rem;
  font-family: NSimSun;
}

.image_5 {
  margin-right: 0%;
  width: 1.69rem;
  height: 1.69rem;
}
.list {
  padding-left: 0.44rem;
}
.list-item {
  justify-content: center;
  padding-bottom: 0.5rem;
  overflow: hidden;
  height: 30rem;
  
}
.section_3 {
  margin-left: -0.5rem;
  margin-top: -0.56rem;
  padding: 5rem 5rem 4.63rem 5.19rem;
  background-color: #ffffff;
  box-shadow:2px 2px #e7e7e7;
  overflow: hidden;
  width: 80.63rem;
  border-left: solid 0.063rem #e7e7e7;
  border-right: solid 0.063rem #e7e7e7;
  border-top: solid 0.063rem #e7e7e7;
  border-bottom: solid 0.063rem #e7e7e7;
}
.image_6 {
  width: 30.88rem;
  height: 20.31rem;
}
.group_9 {
  width: 30.5rem;
}
.font_4 {
  font-size: 2rem;
  font-family: Black Han Sans;
  line-height: 1.81rem;
  color: #000000;
}
.text_12 {
  line-height: 1.85rem;
}
.font_5 {
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 2rem;
  color: #000000;
}
.text_13 {
  margin-left: 0.75rem;
  margin-top: 3.13rem;
  white-space: pre-wrap;
}
.text-wrapper_2 {
  margin-top: 9.38rem;
}
.button {
  padding: 0.25rem 0;
  overflow: hidden;
  width: 10rem;
  border-left: solid 0.063rem #cc3333;
  border-right: solid 0.063rem #cc3333;
  border-top: solid 0.063rem #cc3333;
  border-bottom: solid 0.063rem #cc3333;
}


.link_style {
  text-decoration: none;
}