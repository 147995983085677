.mt-5 {
  margin-top: 0.31rem;
}
.mt-21 {
  margin-top: 1.31rem;
}
.mt-29 {
  margin-top: 1.81rem;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.header {
  padding: 0 1.69rem;
  height: 7.5rem;
}
.image {
  width: 5.06rem;
  height: 5.06rem;
}
.image_2 {
  width: 15.75rem;
  height: 3.5rem;
}
.group {
  margin-right: 0.63rem;
}
.section {
  padding: 3.25rem 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 8.81rem;
  height: 7.5rem;
}
.group_2 {
  line-height: 0.95rem;
}
.font {
  font-size: 1.06rem;
  font-family: SimSun;
  line-height: 0.97rem;
  color: #000000;
}
.text {
  line-height: 0.95rem;
}
.font_2 {
  font-size: 1.06rem;
  font-family: Times New Roman;
  line-height: 0.74rem;
  color: #000000;
}
.text_5 {
  margin-left: 0.38rem;
}
.group_3 {
  line-height: 0.98rem;
}
.text_2 {
  line-height: 0.98rem;
}
.text_6 {
  margin-left: 0.38rem;
}
.group_4 {
  line-height: 0.98rem;
}
.text_3 {
  line-height: 0.98rem;
}
.text_7 {
  line-height: 0.74rem;
}
.group_5 {
  line-height: 0.97rem;
}
.text_4 {
  line-height: 0.97rem;
}
.text_8 {
  margin-left: 0.5rem;
}
.group_6 {
  line-height: 0.97rem;
}
.text_9 {
  margin-left: 0.38rem;
  line-height: 0.74rem;
}
.image_3 {
  margin-left: 1.44rem;
  width: 2.13rem;
  height: 2.13rem;
}
.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 2.63rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.84rem;
}
.section_2 {
  background-color: #fe0000;
  width: 10rem;
  height: 0.13rem;
}
.section_3 {
  padding: 1.63rem 5.25rem 6.38rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 3.2rem;
  color: #000000;
}
.section_4 {
  padding: 2.5rem 5.25rem 3.13rem;
  background-color: #fafafa;
  overflow: hidden;
}
.text-wrapper {
  margin-top: 7.5rem;
  padding: 0.63rem 0;
  background-color: #cccccc;
  overflow: hidden;
  width: 3.31rem;
  height: 3.31rem;
}
.font_4 {
  font-size: 4rem;
  font-family: Estedad-VF;
  line-height: 1.99rem;
  font-weight: 800;
  color: #6e6363;
}
.equal-division {
  align-self: center;
}
.equal-division-item {
  padding-bottom: 1.88rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
  height: 19.38rem;
}
.image_5 {
  width: 15rem;
  height: 15rem;
}
.font_5 {
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 1.15rem;
  color: #000000;
}
.equal-division-item_2 {
  padding-bottom: 1.69rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
  height: 19.38rem;
}
.text_11 {
  margin-top: 1.25rem;
}
.section_5 {
  margin-top: 0.25rem;
  background-color: #ff0000;
  width: 6rem;
  height: 0.13rem;
}
.group_9 {
  margin-top: 25rem;
}
.section_6 {
  padding: 5.69rem 8.69rem 3.63rem;
  background-color: #e7e7e7;
  overflow: hidden;
}
.pos {
  position: absolute;
  left: 8.69rem;
  bottom: 3.25rem;
}
.image_6 {
  width: 10.31rem;
  height: 10.38rem;
}
.image_7 {
  width: 12.19rem;
  height: 2.69rem;
}
.group_10 {
  padding: 0.38rem 1.5rem;
  width: 30.06rem;
  border-left: solid 0.063rem #525252;
  border-right: solid 0.063rem #525252;
}
.text_13 {
  margin-left: 0.19rem;
}
.text-wrapper_2 {
  padding: 6.25rem 0 5.13rem;
  background-color: #d9d9d9;
  width: 12.75rem;
}
.pos_2 {
  position: absolute;
  right: 9.06rem;
  bottom: 3rem;
}
.text_12 {
  line-height: 1.38rem;
}
.text-wrapper_3 {
  padding: 4rem 0 5rem;
  background-color: #232323;
  overflow: hidden;
}
.text_14 {
  color: #ffffff;
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.39rem;
}