.ml-161 {
  margin-left: 10.06rem;
}
.ml-137 {
  margin-left: 8.56rem;
}
.ml-128 {
  margin-left: 8rem;
}
.mt-47 {
  margin-top: 2.94rem;
}
.mt-27 {
  margin-top: 1.69rem;
}
.mt-29 {
  margin-top: 1.81rem;
}

.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 2.5rem;
  padding: 0 5rem;
}
.text-wrapper {
  padding-bottom: 0.25rem;
  border-bottom: solid 0.13rem #fe0000;
}
.text_11 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.84rem;
}
.input {
  margin-right: 0.5rem;
}
.section_2 {
  padding: 0.25rem 0.63rem;
  overflow: hidden;
  width: 15.06rem;
  height: 2.31rem;
}

.button_search {
  margin-right: 0%;
  margin-left: 1.0rem;
  line-height: 1.38rem;
  font-family: NSimSun;
}

.font_3 {
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.39rem;
  color: #000000;
}
.text_12 {
  color: #464646;
}
.image_5 {
  width: 1.69rem;
  height: 1.69rem;
}
.section_3 {
  margin-top: 2.88rem;
  padding: 0 4.88rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.section_4 {
  padding: 2.5rem 2rem 2.5rem 2.25rem;
  background-color: #ffffff;
}
.text_13 {
  line-height: 1.37rem;
}
.font_4 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.04rem;
  color: #000000;
}
.section_7 {
  padding: 1.63rem 2rem 1.5rem 2.25rem;
  background-color: #ffffff;
}
.text-wrapper_2 {
  padding: 0.75rem 0 1rem;
  border-left: solid 0.19rem #ff0000;
}
.text_15 {
  margin-left: 0.75rem;
}
.section_5 {
  margin-right: 1.25rem;
  padding: 1.69rem 0 23.63rem;
  background-color: #ffffff;
  overflow: hidden;
  height: 44.06rem;
}
.section_6 {
  margin-left: 5.63rem;
  padding-bottom: 1.63rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
}
.image_6 {
  width: 15rem;
  height: 9.38rem;
}
.text_14 {
  color: #000000;
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 1.15rem;
}
.button {
  padding: 0.25rem 0;
  overflow: hidden;
  width: 10rem;
  border-left: solid 0.063rem #cc3333;
  border-right: solid 0.063rem #cc3333;
  border-top: solid 0.063rem #cc3333;
  border-bottom: solid 0.063rem #cc3333;
}
.text_16 {
  color: #cc3333;
  line-height: 1.38rem;
}
.group_9 {
  margin-top: 30.44rem;
}
.section_8 {
  padding: 5.69rem 8.69rem 3.63rem;
  background-color: #e7e7e7;
  overflow: hidden;
}
.pos {
  position: absolute;
  left: 8.69rem;
  bottom: 3.25rem;
}
.image_7 {
  width: 10.31rem;
  height: 10.38rem;
}
.image_8 {
  width: 12.19rem;
  height: 2.69rem;
}
.group_10 {
  padding: 0.38rem 1.5rem;
  width: 30.06rem;
  border-left: solid 0.063rem #525252;
  border-right: solid 0.063rem #525252;
}
.font_5 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.81rem;
  color: #000000;
}
.text_18 {
  margin-left: 0.19rem;
}
.text-wrapper_3 {
  padding: 6.25rem 0 5.13rem;
  background-color: #d9d9d9;
  width: 12.75rem;
}
.pos_2 {
  position: absolute;
  right: 9.06rem;
  bottom: 3rem;
}
.text_17 {
  line-height: 1.38rem;
}
.text-wrapper_4 {
  padding: 4rem 0 5rem;
  background-color: #232323;
  overflow: hidden;
}
.text_19 {
  color: #ffffff;
}