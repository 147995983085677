.mt-43 {
  margin-top: 2.69rem;
}
.ml-47 {
  margin-left: 2.94rem;
}
.ml-31 {
  margin-left: 1.94rem;
}
.mt-23 {
  margin-top: 1.44rem;
}
.page {
  background-color: #ffffff;
  background-image: url('https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=540191fdd312dee1563b171214c16c5a.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  background-image: url('https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=540191fdd312dee1563b171214c16c5a.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90rem;
  height: 50.63rem;
}
.section_2 {
  padding: 1.38rem 1.44rem 4.13rem;
  background-color: #d9d9d980;
  width: 58.63rem;
}
.pos {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.image {
  width: 2.13rem;
  height: 1.81rem;
}
.group {
  padding-left: 2.75rem;
  padding-right: 1.81rem;
}
.group_2 {
  margin-bottom: 0.25rem;
}
.image_2 {
  width: 14.5rem;
  height: 14.56rem;
}
.image_4 {
  width: 17.13rem;
  height: 3.81rem;
}
.text {
  color: #000000;
  font-size: 2.25rem;
  font-family: NSimSun;
  line-height: 2.07rem;
}
.section_3 {
  padding: 0.5rem 0.44rem;
  background-color: #ffffff;
}
.view {
  margin-top: 1.13rem;
}
.image_3 {
  width: 2.81rem;
  height: 2.81rem;
}
.font {
  font-size: 0.88rem;
  font-family: NSimSun;
  line-height: 0.81rem;
  color: #8a8a8a;
}
.text_2 {
  margin-right: 17.5rem;
}
.view_2 {
  margin-top: 1rem;
}
.text_3 {
  margin-right: 21rem;
}
.button {
  align-self: stretch;
  margin-top: 2rem;
}
.text-wrapper {
  padding: 1rem 0;
  background-color: #409eff;
  width: 31.13rem;
}
.text_4 {
  color: #ffffff;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.79rem;
}
.group_3 {
  padding: 0 0.88rem;
}
.font_2 {
  font-size: 0.81rem;
  font-family: NSimSun;
  line-height: 0.75rem;
  color: #353535;
}
.text_5 {
  line-height: 0.73rem;
}