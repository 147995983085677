.mt-5 {
  margin-top: 0.31rem;
}
.mt-23 {
  margin-top: 1.44rem;
}
.mt-29 {
  margin-top: 1.81rem;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 2.63rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.84rem;
}
.section_2 {
  background-color: #fe0000;
  width: 8rem;
  height: 0.13rem;
}
.section_3 {
  padding: 1.63rem 5.25rem 2rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.81rem;
  color: #000000;
}
.section_4 {
  padding: 2.5rem 5.25rem 3.13rem;
  background-color: #fafafa;
  overflow: hidden;
}
.text-wrapper {
  margin-top: 7.5rem;
  padding: 0.63rem 0;
  background-color: #cccccc;
  overflow: hidden;
  width: 3.31rem;
  height: 3.31rem;
}
.font_4 {
  font-size: 4rem;
  font-family: Estedad-VF;
  line-height: 1.99rem;
  font-weight: 800;
  color: #6e6363;
}
.equal-division {
  align-self: center;
}
.equal-division-item {
  padding-bottom: 1.5rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
  height: 19.38rem;
}
.image_5 {
  width: 15rem;
  height: 15rem;
}
.font_5 {
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 1.15rem;
  color: #000000;
}
.text_11 {
  margin-top: 1.25rem;
}
.section_5 {
  margin-top: 0.25rem;
  background-color: #ff0000;
  width: 4.7rem;
  height: 0.13rem;
}
.equal-division-item_2 {
  padding-bottom: 2rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
  height: 19.38rem;
}
