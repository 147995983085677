.mt-5 {
  margin-top: 0.31rem;
}
.mt-23 {
  margin-top: 1.44rem;
}
.mt-29 {
  margin-top: 1.81rem;
}
.page {
  background-color: #CCDDEA;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group_7 {
  overflow-y: auto;
  
}
.image_4 {
  height: 36vw;
}
.group_8 {
  margin-top: 2rem;
}
.font_3 {
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.85rem;
  color: #ffffff;
}
.text_10 {
  color: #000000;
}
.section_2 {
  background-color: #fe0000;
  width: 10rem;
  height: 0.13rem;
}
.group_9 {
  margin-top: 2.25rem;
  padding: 0 4.69rem;
}
.group_10 {
  padding-bottom: 2rem;
  flex: 1 1 26.88rem;
}
.text-wrapper {
  padding: 1.63rem 0;
  background-color: #cc3333;
  overflow: hidden;
}
.text_11 {
  line-height: 1.84rem;
}
.image_5 {
  width: 6rem;
  height: 2.69rem;
}
.pos {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.text-wrapper_2 {
  flex: 1 1 26.88rem;
  padding: 1.63rem 0;
  background-color: #cccccc;
  overflow: hidden;
  height: 5.06rem;
}
.text-wrapper_2:hover {
  background-color: #cc3333;
}

.image_6 {
  height: 24.06rem;
  width: 32rem;
}
.group_11 {
  margin-right: 3rem;
  margin-top: 1.13rem;
  width: 31.86rem;
}
.text_12 {
  margin-left: 0.38rem;
  color: #000000;
  font-size: 2rem;
  font-family: Black Han Sans;
  line-height: 1.85rem;
}
.text_13 {
  margin-top: 2.25rem;
  color: #000000;
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 3rem;
}
.button {
  align-self: flex-start;
  margin-top: 3rem;
}
.text-wrapper_3 {
  padding: 0.25rem 0;
  overflow: hidden;
  width: 10rem;
  border-left: solid 0.063rem #cc3333;
  border-right: solid 0.063rem #cc3333;
  border-top: solid 0.063rem #cc3333;
  border-bottom: solid 0.063rem #cc3333;
}
.font_4 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.38rem;
}
.text_14 {
  color: #cc3333;
  line-height: 1.37rem;
}
.equal-division {
  margin: 6.38rem 6.13rem 0;
}
.group_12 {
  flex: 1 1 25.94rem;
}
.equal-division-item {
  padding: 0.5rem 0;
}
.image_7 {
  width: 15rem;
  height: 15rem;
}
.font_5 {
  font-size: 2.25rem;
  font-family: SimSun;
  line-height: 2.06rem;
  color: #222221;
}
/* .group_13 {
  margin-top: 6.75rem;
}
.section_3 {
  padding: 5.69rem 8.69rem 3.63rem;
  background-color: #e7e7e7;
  overflow: hidden;
}
.pos_2 {
  position: absolute;
  left: 8.69rem;
  bottom: 3.25rem;
}
.image_8 {
  width: 10.31rem;
  height: 10.38rem;
}
.image_9 {
  width: 12.19rem;
  height: 2.69rem;
}
.group_14 {
  padding: 0.38rem 1.5rem;
  width: 30.06rem;
  border-left: solid 0.063rem #525252;
  border-right: solid 0.063rem #525252;
}
.font_6 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.85rem;
  color: #000000;
}
.text_15 {
  line-height: 1.81rem;
}
.text_17 {
  margin-left: 0.19rem;
  line-height: 1.81rem;
}
.text_18 {
  line-height: 1.81rem;
}
.text-wrapper_4 {
  padding: 6.25rem 0 5.13rem;
  background-color: #d9d9d9;
  width: 12.75rem;
}
.pos_3 {
  position: absolute;
  right: 9.06rem;
  bottom: 3rem;
}
.text_16 {
  line-height: 1.38rem;
}
.text-wrapper_5 {
  padding: 4rem 0 5rem;
  background-color: #232323;
  overflow: hidden;
}
.text_19 {
  color: #ffffff;
  line-height: 1.39rem;
} */