.mt-2-5 {
  margin-top: 0.16rem;
}
.mt-31 {
  margin-top: 1.94rem;
}
.mt-14-5 {
  margin-top: 0.91rem;
}

.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 1.44rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.82rem;
}
.section_2 {
  background-color: #fe0000;
  width: 8rem;
  height: 0.13rem;
}
.group_9 {
  margin-top: 2.19rem;
}
.text-wrapper {
  margin: 0 2.34rem;
  overflow: hidden;
  height: 10rem;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 3.63rem;
  color: #000000;
}
.text_11 {
  margin-right: 0.19rem;
  line-height: 2.4rem;

}
.section_3 {
  padding: 2.84rem 4.34rem 1.81rem;
  background-color: #e7e7e7;
  overflow: hidden;
}
.pos {
  position: absolute;
  left: 4.34rem;
  bottom: 1.63rem;
}
.image_5 {
  width: 5.16rem;
  height: 5.19rem;
}
.image_6 {
  width: 6.09rem;
  height: 1.34rem;
}
.group_10 {
  padding: 0.19rem 0.75rem;
  width: 15.03rem;
  border-left: solid 0.031rem #525252;
  border-right: solid 0.031rem #525252;
}
.text_13 {
  margin-left: 0.094rem;
}
.text-wrapper_2 {
  padding: 3.13rem 0 2.56rem;
  background-color: #d9d9d9;
  width: 6.38rem;
}
.pos_2 {
  position: absolute;
  right: 4.53rem;
  bottom: 1.5rem;
}
.text_12 {
  line-height: 0.69rem;
}
.text-wrapper_3 {
  padding: 2rem 0 2.5rem;
  background-color: #232323;
  overflow: hidden;
}
.text_14 {
  color: #ffffff;
  font-size: 0.75rem;
  font-family: NSimSun;
  line-height: 0.69rem;
}