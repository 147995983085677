.mt-5 {
  margin-top: 0.31rem;
}
.ml-125 {
  margin-left: 7.81rem;
}
.mt-21 {
  margin-top: 1.31rem;
}
.mt-29 {
  margin-top: 1.81rem;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 2.88rem;
  padding: 0 4.69rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.85rem;
}
.section_2 {
  background-color: #fe0000;
  width: 10rem;
  height: 0.13rem;
}
.group_9 {
  margin-top: 2rem;
}
.section_3 {
  padding: 2.5rem 5.13rem 3.13rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.39rem;
  color: #000000;
}
.text_11 {
  line-height: 1.38rem;
}
.pos {
  position: absolute;
  left: 5.12rem;
  top: 2.51rem;
}
.equal-division {
  align-self: center;
}
.equal-division-item {
  padding-bottom: 2rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 15rem;
  height: 19.38rem;
}
.image_5 {
  width: 15rem;
  height: 15rem;
}
.font_4 {
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 1.15rem;
  color: #000000;
}
.text_12 {
  line-height: 1.15rem;
}
.text_13 {
  line-height: 1.15rem;
}
.section_4 {
  padding: 2.25rem 3rem 3.38rem 4.88rem;
  background-color: #fafafa;
  overflow: hidden;
}
.text_14 {
  margin-top: 0.25rem;
}
.section_5 {
  flex: 1 1 15rem;
}
.section_6 {
  padding: 2.5rem 4.88rem 3.13rem;
  background-color: #f5f5f5;
  overflow: hidden;
}
.pos_2 {
  position: absolute;
  left: 4.91rem;
  top: 2.51rem;
}
.text_15 {
  line-height: 1.13rem;
}
.text_16 {
  line-height: 1.13rem;
}
