.mt-2-5 {
  margin-top: 0.16rem;
}
.mt-12-5 {
  margin-top: 0.78rem;
}
.mt-14-5 {
  margin-top: 0.91rem;
}

.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 1.44rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.82rem;
}
.section_2 {
  background-color: #fe0000;
  width: 8rem;
  height: 0.13rem;
}
.group_9 {
  margin-top: 2.19rem;
}
.group_10 {
  margin: 0 2.34rem;
  padding-bottom: 1.06rem;
  overflow: hidden;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 3.63rem;
  color: #000000;
}
.text_11 {
  margin-left: 0.13rem;
}
