.mt-39 {
  margin-top: 2.44rem;
}
.ml-15 {
  margin-left: 0.94rem;
}
.ml-31 {
  margin-left: 1.94rem;
}
.mt-13 {
  margin-top: 0.81rem;
}
.page {
  background-color: #ffffff;
  background-image: url('https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=540191fdd312dee1563b171214c16c5a.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section {
  background-image: url('https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=540191fdd312dee1563b171214c16c5a.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 90rem;
  height: 50.63rem;
}
.section_2 {
  padding: 1.38rem 6.25rem 4.63rem;
  background-color: #d9d9d980;
  width: 35.25rem;
}
.pos {
  position: absolute;
  left: 26.56rem;
  top: 4.38rem;
}
.group {
  padding: 0 5.25rem;
}
.image {
  width: 3.63rem;
  height: 3.63rem;
}
.image_2 {
  width: 8.56rem;
  height: 1.88rem;
}
.font {
  font-size: 1.25rem;
  font-family: NSimSun;
  line-height: 1.12rem;
  color: #ffffff;
}
.text {
  color: #000000;
  line-height: 1.15rem;
}
.form-item {
  margin-right: 1.25rem;
}
.font_2 {
  font-size: 0.88rem;
  font-family: NSimSun;
  line-height: 0.8rem;
  color: #000000;
}
.section_3 {
  flex: 1 1 0;
}
.input {
  background-color: #ffffff;
  height: 2.44rem;
}
.form-item_2 {
  margin-right: 1.25rem;
  margin-top: 0.63rem;
}
.text_2 {
  line-height: 0.79rem;
}
.text_3 {
  line-height: 0.79rem;
}
.text_4 {
  line-height: 0.8rem;
}
.group_2 {
  padding: 0 4.13rem;
}
.button {
  padding: 0.63rem 0;
  background-color: #409eff;
  width: 12.5rem;
}
.text_5 {
  line-height: 1.11rem;
}