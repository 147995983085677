.mt-5 {
  margin-top: 0.31rem;
}
.mt-29 {
  margin-top: 1.81rem;
}

.group_7 {
  overflow-y: auto;
}
.image_4 {
  width: 100vw;
  height: 36vw;
}
.group_8 {
  margin-top: 2.94rem;
}
.text_10 {
  color: #000000;
  font-size: 2rem;
  font-family: NSimSun;
  line-height: 1.82rem;
}
.section_2 {
  background-color: #fe0000;
  width: 8rem;
  height: 0.13rem;
}
.group_10 {
  margin: 3.63rem 4.69rem 0;
  padding-bottom: 1.38rem;
  overflow: hidden;
  height: 24.81rem;
}
.group_9 {
  margin-right: 1.25rem;
}
.font_3 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 3.63rem;
  color: #000000;
}
.text_11 {
  text-decoration: underline;
}
.group_11 {
  margin-top: 5.75rem;
}
.section_3 {
  padding: 5.69rem 8.69rem 3.63rem;
  background-color: #e7e7e7;
  overflow: hidden;
}
.pos {
  position: absolute;
  left: 8.69rem;
  bottom: 3.25rem;
}
.image_5 {
  width: 10.31rem;
  height: 10.38rem;
}
.image_6 {
  width: 12.19rem;
  height: 2.69rem;
}
.group_12 {
  padding: 0.38rem 1.5rem;
  width: 30.06rem;
  border-left: solid 0.063rem #525252;
  border-right: solid 0.063rem #525252;
}
.font_4 {
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.81rem;
  color: #000000;
}
.text_13 {
  margin-left: 0.19rem;
}
.text-wrapper {
  padding: 6.25rem 0 5.13rem;
  background-color: #d9d9d9;
  width: 12.75rem;
}
.pos_2 {
  position: absolute;
  right: 9.06rem;
  bottom: 3rem;
}
.text_12 {
  line-height: 1.38rem;
}
.text-wrapper_2 {
  padding: 4rem 0 5rem;
  background-color: #232323;
  overflow: hidden;
}
.text_14 {
  color: #ffffff;
  font-size: 1.5rem;
  font-family: NSimSun;
  line-height: 1.39rem;
}