 .group_13 {
    margin-top: 6.75rem;
    background-color:#CCDDEA;
  }
  .section_3 {
    padding: 5.69rem 8.69rem 3.63rem;
    background-color: #e7e7e7;
    overflow: hidden;
  }
  .pos_2 {
    position: absolute;
    left: 8.69rem;
    bottom: 3.25rem;
  }
  .image_8 {
    width: 10.31rem;
    height: 10.38rem;
  }
  .image_9 {
    width: 12.19rem;
    height: 2.69rem;
  }
  .group_14 {
    padding: 0.38rem 1.5rem;
    width: 30.06rem;
    border-left: solid 0.063rem #525252;
    border-right: solid 0.063rem #525252;
  }
  .font_6 {
    font-size: 1.5rem;
    font-family: NSimSun;
    line-height: 1.85rem;
    color: #000000;
  }
  .text_15 {
    line-height: 1.81rem;
  }
  .text_17 {
    margin-left: 0.19rem;
    line-height: 1.81rem;
  }
  .text_18 {
    line-height: 1.81rem;
  }
  .text-wrapper_4 {
    padding: 6.25rem 0 5.13rem;
    background-color: #d9d9d9;
    width: 12.75rem;
  }
  .pos_3 {
    position: absolute;
    right: 9.06rem;
    bottom: 3rem;
  }
  .text_16 {
    line-height: 1.38rem;
  }
  .text-wrapper_5 {
    padding: 4rem 0 5rem;
    background-color: #232323;
    overflow: hidden;
  }
  .text_19 {
    color: #ffffff;
    line-height: 1.39rem;
  }