.header {
    padding: 0 1.69rem;
    height: 7.5rem;
  }
  .image {
    width: 4.2rem;
    height: 4.2rem;
  }
  .image_2 {
    width: 13.5rem;
    height: 3.0rem;
  }
  .group {
    margin-right: 0.63rem;
  }
  .section {
    padding: 3.25rem 0;
    background-color: #ffffff;
    overflow: hidden;
    width: 8.81rem;
    height: 7.5rem;
    text-decoration: none;
  }

  .nav_link {
    display: inline-block;
    text-decoration: none;
    color: #000000;
  }

  .nav_link:hover {
    padding-bottom: 10px;
    border-bottom: 2px solid red;
  }

  /* span {
    width: 100%;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
  } */

  .group_2 {
    line-height: 0.95rem;
  }
  .font {
    font-size: 1.06rem;
    font-family: SimSun;
    line-height: 0.97rem;
    color: #000000;
  }
  .text {
    line-height: 0.95rem;
  }
  .font_2 {
    font-size: 1.06rem;
    font-family: Times New Roman;
    line-height: 0.74rem;
    color: #000000;
  }
  .text_5 {
    margin-left: 0.38rem;
  }
  .group_3 {
    line-height: 0.98rem;
  }
  .text_2 {
    line-height: 0.98rem;
  }
  .text_6 {
    margin-left: 0.38rem;
  }
  .group_4 {
    line-height: 0.98rem;
  }
  .text_3 {
    line-height: 0.98rem;
  }
  .text_7 {
    line-height: 0.74rem;
  }
  .group_5 {
    line-height: 0.97rem;
  }
  .text_4 {
    line-height: 0.97rem;
  }
  .text_8 {
    margin-left: 0.5rem;
  }
  .group_6 {
    line-height: 0.97rem;
  }
  .text_9 {
    margin-left: 0.38rem;
    line-height: 0.74rem;
  }

  .dropdown {
    position: absolute;
    z-index: 99;
    margin-left: 0.5rem;
    margin-top: 2.5rem;
    padding-left: 1.2rem;
    line-height: 1.97rem;
    list-style: none;
    background-color: #ffffff;
    width: 8.81rem;
    
  }

  .submenu {
    margin-top: 5px;
    margin-bottom: 5px;
    color:#000000;
    justify-content: flex-start;
    width: 8.81rem;
    height: 1.2rem;
  }

  .text_10 {
    line-height: 0.2rem;
  }

  .image_3 {
    margin-left: 1.44rem;
    width: 2.13rem;
    height: 2.13rem;
  }

  